// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import VideoCall5 from "../../blocks/VideoCall5/src/VideoCall5";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import BarcodeSettings from "../../blocks/BarcodeSettings/src/BarcodeSettings";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import ProductDescription from "../../blocks/ProductDescription/src/ProductDescription";
import CustomisableUserProfiles from "../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles";
import CollectTransactionFees from "../../blocks/CollectTransactionFees/src/CollectTransactionFees";
import HelpCenter from "../../blocks/HelpCenter/src/HelpCenter";
import TeamBuilder from "../../blocks/TeamBuilder/src/TeamBuilder";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import DataEncryption from "../../blocks/DataEncryption/src/DataEncryption";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import CfServiceLisitng from "../../blocks/CfServiceLisitng/src/CfServiceLisitng";
import Ordermanagement from "../../blocks/ordermanagement/src/Ordermanagement";
import OrderDetails from "../../blocks/ordermanagement/src/OrderDetails";
import PhotoLibrary3 from "../../blocks/PhotoLibrary3/src/PhotoLibrary3";
import CfInsuranceListing from "../../blocks/CfInsuranceListing/src/CfInsuranceListing";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Location from "../../blocks/location/src/Location";
import VideoLibrary from "../../blocks/VideoLibrary/src/VideoLibrary";
import CfGoogleDatabaseAccess from "../../blocks/CfGoogleDatabaseAccess/src/CfGoogleDatabaseAccess";
import Maps from "../../blocks/maps/src/Maps";
import AppointmentManagement from "../../blocks/AppointmentManagement/src/AppointmentManagement";
import ChatBackuprestore from "../../blocks/ChatBackuprestore/src/ChatBackuprestore";
import InventoryManagement23 from "../../blocks/InventoryManagement23/src/InventoryManagement23";
import EmailNotifications from "../../blocks/EmailNotifications/src/EmailNotifications";
import CfWhatsapp6 from "../../blocks/CfWhatsapp6/src/CfWhatsapp6";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import RolesPermissions2 from "../../blocks/RolesPermissions2/src/RolesPermissions2";
import CfAyushmanBharatApi from "../../blocks/CfAyushmanBharatApi/src/CfAyushmanBharatApi";
import Scheduling from "../../blocks/scheduling/src/Scheduling";
import EmailAccountLoginBlock from "../../blocks/email-account-login/src/EmailAccountLoginBlock";
import HamburgerMenu from "../../blocks/HamburgerMenu/src/HamburgerMenu";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import ContentModeration from "../../blocks/ContentModeration/src/ContentModeration";
import VideoEmbeddingYoutube from "../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube";
import OrderDetailView from "../../blocks/OrderDetailView/src/OrderDetailView";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import LeadManagement from "../../blocks/LeadManagement/src/LeadManagement";
import CfLoanListing from "../../blocks/CfLoanListing/src/CfLoanListing";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import SubscriptionBilling from "../../blocks/SubscriptionBilling/src/SubscriptionBilling";
import UploadMedia2 from "../../blocks/UploadMedia2/src/UploadMedia2";
import StoreCredits from "../../blocks/StoreCredits/src/StoreCredits";
import CustomAdvertisements from "../../blocks/CustomAdvertisements/src/CustomAdvertisements";
import ElasticSearch from "../../blocks/ElasticSearch/src/ElasticSearch";
import AccountScoreranking from "../../blocks/AccountScoreranking/src/AccountScoreranking";
import CustomisedOrderStatus from "../../blocks/CustomisedOrderStatus/src/CustomisedOrderStatus";
import Chatbot6 from "../../blocks/Chatbot6/src/Chatbot6";
import LanguageSupport from "../../blocks/LanguageSupport/src/LanguageSupport";
import Payments from "../../blocks/Payments/src/Payments";
import Translation3 from "../../blocks/Translation3/src/Translation3";
import OrderSummary from "../../blocks/OrderSummary/src/OrderSummary";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import Videos from "../../blocks/videos/src/Videos";
import GoogleAssistantIntegration from "../../blocks/GoogleAssistantIntegration/src/GoogleAssistantIntegration";
import CfAppleHealthKit from "../../blocks/CfAppleHealthKit/src/CfAppleHealthKit";
import AdminConsole3 from "../../blocks/AdminConsole3/src/AdminConsole3";
import ShoppingCart from "../../blocks/ShoppingCart/src/ShoppingCart";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import PaymentAdmin2 from "../../blocks/PaymentAdmin2/src/PaymentAdmin2";
import Settings5 from "../../blocks/Settings5/src/Settings5";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import CfWhatsappLogin from "../../blocks/CfWhatsappLogin/src/CfWhatsappLogin";
import CfInsurance from "../../blocks/CfInsurance/src/CfInsurance";
import Trending from "../../blocks/Trending/src/Trending";
import CfFacebookDatabaseAccess2 from "../../blocks/CfFacebookDatabaseAccess2/src/CfFacebookDatabaseAccess2";
import CfAppleLogin7 from "../../blocks/CfAppleLogin7/src/CfAppleLogin7";
import CfGoogleFit from "../../blocks/CfGoogleFit/src/CfGoogleFit";
import TermsAndConditions from "../../blocks/TermsAndConditions/src/TermsAndConditions";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import BarcodesScanner from "../../blocks/BarcodesScanner/src/BarcodesScanner";
import AudioCall from "../../blocks/AudioCall/src/AudioCall";
import Chat9 from "../../blocks/Chat9/src/Chat9";
import Customisableusersubscriptions from "../../blocks/customisableusersubscriptions/src/Customisableusersubscriptions";
import SubscriptionDetails from "../../blocks/customisableusersubscriptions/src/SubscriptionDetails";
import PdfConvert from "../../blocks/PdfConvert/src/PdfConvert";
import InvoiceBilling from "../../blocks/InvoiceBilling/src/InvoiceBilling";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import Analytics from "../../blocks/analytics/src/Analytics";
import PeopleManagement2 from "../../blocks/PeopleManagement2/src/PeopleManagement2";
import ActivityFeed from "../../blocks/ActivityFeed/src/ActivityFeed";
import OcropticalCharacterRecognition from "../../blocks/OcropticalCharacterRecognition/src/OcropticalCharacterRecognition";



const routeMap = {
VideoCall5:{
 component:VideoCall5,
path:"/VideoCall5"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
BarcodeSettings:{
 component:BarcodeSettings,
path:"/BarcodeSettings"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
ProductDescription:{
 component:ProductDescription,
path:"/ProductDescription"},
CustomisableUserProfiles:{
 component:CustomisableUserProfiles,
path:"/CustomisableUserProfiles"},
CollectTransactionFees:{
 component:CollectTransactionFees,
path:"/CollectTransactionFees"},
HelpCenter:{
 component:HelpCenter,
path:"/HelpCenter"},
TeamBuilder:{
 component:TeamBuilder,
path:"/TeamBuilder"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
DataEncryption:{
 component:DataEncryption,
path:"/DataEncryption"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
CfServiceLisitng:{
 component:CfServiceLisitng,
path:"/CfServiceLisitng"},
Ordermanagement:{
 component:Ordermanagement,
path:"/Ordermanagement"},
OrderDetails:{
 component:OrderDetails,
path:"/OrderDetails"},
PhotoLibrary3:{
 component:PhotoLibrary3,
path:"/PhotoLibrary3"},
CfInsuranceListing:{
 component:CfInsuranceListing,
path:"/CfInsuranceListing"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Location:{
 component:Location,
path:"/Location"},
VideoLibrary:{
 component:VideoLibrary,
path:"/VideoLibrary"},
CfGoogleDatabaseAccess:{
 component:CfGoogleDatabaseAccess,
path:"/CfGoogleDatabaseAccess"},
Maps:{
 component:Maps,
path:"/Maps"},
AppointmentManagement:{
 component:AppointmentManagement,
path:"/AppointmentManagement"},
ChatBackuprestore:{
 component:ChatBackuprestore,
path:"/ChatBackuprestore"},
InventoryManagement23:{
 component:InventoryManagement23,
path:"/InventoryManagement23"},
EmailNotifications:{
 component:EmailNotifications,
path:"/EmailNotifications"},
CfWhatsapp6:{
 component:CfWhatsapp6,
path:"/CfWhatsapp6"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
RolesPermissions2:{
 component:RolesPermissions2,
path:"/RolesPermissions2"},
CfAyushmanBharatApi:{
 component:CfAyushmanBharatApi,
path:"/CfAyushmanBharatApi"},
Scheduling:{
 component:Scheduling,
path:"/Scheduling"},
EmailAccountLoginBlock:{
 component:EmailAccountLoginBlock,
path:"/EmailAccountLoginBlock"},
HamburgerMenu:{
 component:HamburgerMenu,
path:"/HamburgerMenu"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
ContentModeration:{
 component:ContentModeration,
path:"/ContentModeration"},
VideoEmbeddingYoutube:{
 component:VideoEmbeddingYoutube,
path:"/VideoEmbeddingYoutube"},
OrderDetailView:{
 component:OrderDetailView,
path:"/OrderDetailView"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
LeadManagement:{
 component:LeadManagement,
path:"/LeadManagement"},
CfLoanListing:{
 component:CfLoanListing,
path:"/CfLoanListing"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
SubscriptionBilling:{
 component:SubscriptionBilling,
path:"/SubscriptionBilling"},
UploadMedia2:{
 component:UploadMedia2,
path:"/UploadMedia2"},
StoreCredits:{
 component:StoreCredits,
path:"/StoreCredits"},
CustomAdvertisements:{
 component:CustomAdvertisements,
path:"/CustomAdvertisements"},
ElasticSearch:{
 component:ElasticSearch,
path:"/ElasticSearch"},
AccountScoreranking:{
 component:AccountScoreranking,
path:"/AccountScoreranking"},
CustomisedOrderStatus:{
 component:CustomisedOrderStatus,
path:"/CustomisedOrderStatus"},
Chatbot6:{
 component:Chatbot6,
path:"/Chatbot6"},
LanguageSupport:{
 component:LanguageSupport,
path:"/LanguageSupport"},
Payments:{
 component:Payments,
path:"/Payments"},
Translation3:{
 component:Translation3,
path:"/Translation3"},
OrderSummary:{
 component:OrderSummary,
path:"/OrderSummary"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
Videos:{
 component:Videos,
path:"/Videos"},
GoogleAssistantIntegration:{
 component:GoogleAssistantIntegration,
path:"/GoogleAssistantIntegration"},
CfAppleHealthKit:{
 component:CfAppleHealthKit,
path:"/CfAppleHealthKit"},
AdminConsole3:{
 component:AdminConsole3,
path:"/AdminConsole3"},
ShoppingCart:{
 component:ShoppingCart,
path:"/ShoppingCart"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
PaymentAdmin2:{
 component:PaymentAdmin2,
path:"/PaymentAdmin2"},
Settings5:{
 component:Settings5,
path:"/Settings5"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
CfWhatsappLogin:{
 component:CfWhatsappLogin,
path:"/CfWhatsappLogin"},
CfInsurance:{
 component:CfInsurance,
path:"/CfInsurance"},
Trending:{
 component:Trending,
path:"/Trending"},
CfFacebookDatabaseAccess2:{
 component:CfFacebookDatabaseAccess2,
path:"/CfFacebookDatabaseAccess2"},
CfAppleLogin7:{
 component:CfAppleLogin7,
path:"/CfAppleLogin7"},
CfGoogleFit:{
 component:CfGoogleFit,
path:"/CfGoogleFit"},
TermsAndConditions:{
 component:TermsAndConditions,
path:"/TermsAndConditions"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
BarcodesScanner:{
 component:BarcodesScanner,
path:"/BarcodesScanner"},
AudioCall:{
 component:AudioCall,
path:"/AudioCall"},
Chat9:{
 component:Chat9,
path:"/Chat9"},
Customisableusersubscriptions:{
 component:Customisableusersubscriptions,
path:"/Customisableusersubscriptions"},
SubscriptionDetails:{
 component:SubscriptionDetails,
path:"/SubscriptionDetails"},
PdfConvert:{
 component:PdfConvert,
path:"/PdfConvert"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
Analytics:{
 component:Analytics,
path:"/Analytics"},
PeopleManagement2:{
 component:PeopleManagement2,
path:"/PeopleManagement2"},
ActivityFeed:{
 component:ActivityFeed,
path:"/ActivityFeed"},
OcropticalCharacterRecognition:{
 component:OcropticalCharacterRecognition,
path:"/OcropticalCharacterRecognition"},

  Home: {
component:Analytics,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
