import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../../framework/src/Messages/MessageEnum";
// Customizable Area Start
import {
  imgPasswordVisible,
  imgPasswordInVisible,
  labelHeader,
  errorTitle,
  errorBothPasswordsNotSame,
  errorEmailNotValid,
  validationApiContentType,
  urlGetValidations,
  validationApiMethodType,
  errorAllFieldsAreMandatory,
  contentTypeApiAddDetail,
  apiEndPointAddDetail,
  apiMethodTypeAddDetail,
  labelFirstName,
  lastName,
  labelEmail,
  labelPassword,
  labelLegalText,
  labelRePassword,
  labelLegalTermCondition,
  labelLegalPrivacyPolicy,
  btnTextSignUp,
  errorPasswordNotValid
} from "./config";
import { throws } from "assert";
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class AdditionalDetailFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  addAdditionalDetailApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = labelHeader;
    this.labelFirstName = labelFirstName;
    this.lastName = lastName;
    this.labelEmail = labelEmail;
    this.labelPassword = labelPassword;
    this.labelRePassword = labelRePassword;
    this.labelLegalText = labelLegalText;
    this.labelLegalTermCondition = labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = labelLegalPrivacyPolicy;
    this.btnTextSignUp = btnTextSignUp;
    // Customizable Area End
    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.addAdditionalDetailApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  addAdditionalDetail(): boolean {
    
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(errorTitle, errorAllFieldsAreMandatory);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(errorTitle, errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(errorTitle, errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(errorTitle, errorBothPasswordsNotSame);
      return false;
    }

    const header = {
      "Content-Type": contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password
    };

    const data = {
      type: "sms_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAdditionalDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiEndPointAddDetail
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      apiMethodTypeAddDetail
    );

    //BxConstant.debugLog("Request Additional details", requestMessage);

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  processOnClickMessage(messageID:string, values:any = null) {
    switch(messageID) {
      case 'btnLegalTermsAndCondition':
        this.goToTermsAndCondition();
        break;
      case 'btnLegalPrivacyPolicy':
        this.goToPrivacyPolicy();
        break;
      case 'btnSignUp':
        this.addAdditionalDetail();
        break;
      default:
        console.log("processOnClickMessage::Not Confifured for message " + messageID);
        break;
    }

  }

  public changeState(objectID:string, value:any = null) {
    switch(objectID) {
      case 'txtInputFirstName':
        this.setState({firstName: value});
        break;
      case 'txtInputLastName':
        this.setState({lastName: value});
        break;
      case 'txtInputEmail':
        this.setState({email: value});
        break;
      case 'txtInputPassword':
        this.setState({password: value});
        break;
      case 'btnPasswordShowHide':
        this.setState({enablePasswordField: value});
        break;
      case 'txtInputConfirmPassword':
        this.setState({reTypePassword: value});
        break;
      case 'btnConfirmPasswordShowHide':
        this.setState({enableReTypePasswordField: value});
        break;
      default:
        console.log("changeState::Not Confifured for " + objectID);
    }
  }

  getState(objectID:string) {
    switch(objectID) {
      case 'txtInputFirstName':
        return this.state.firstName;
      case 'enablePasswordField':
      case 'txtInputPassword':
      case 'imgEnablePasswordField':
      case 'txtInputConfirmPassword':
      case 'btnPasswordShowHide':
        return this.state.enablePasswordField;
      case 'enableConfirmPasswordField':
      case 'btnConfirmPasswordShowHide':
      case 'imgEnableRePasswordField':
        return this.state.enableReTypePasswordField;
      default:
        console.log("getState::Not Confifured for " + objectID);
        return null;
    }
  }
  // Customizable Area End
}
